'use strict';

/**
 *  Load modules
 */
import '../scss/app.scss';
jQuery(document).ready(function ($) {
  // stick header
  stickHeader();
  $(window).scroll(function () {
    stickHeader();
  });
  function stickHeader() {
    if ($(window).scrollTop() > 1) {
      $('.header').addClass('header--stick');
    } else {
      $('.header').removeClass('header--stick');
    }
  }

  // Read cookies
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // auto fill user country
  (function () {
    var userCountryName = readCookie('userCountryName');
    if (userCountryName) {
      if ($('.country-auto-fill').length) {
        $('.country-auto-fill input').val(userCountryName).parents('.formrow').addClass('formrow--changed formrow--correct');
      }
    }
  })();

  // Close cookies message
  function setAgreeWithCookEvent(event) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': event
    });
  }
  $(".cookie-message__close").click(function (e) {
    e.preventDefault();
    var d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = "agreeewithcook=1; " + expires + "; path=/";
    $(".cookie-message").animate({
      bottom: '-100%'
    }, 500, function () {
      $(".cookie-message").remove();
    });
    setAgreeWithCookEvent('agreewithcook-1');
  });
  var agreewithcook = readCookie('agreeewithcook');
  if (!agreewithcook) {
    $(".cookie-message").addClass('show');
  } else {
    setAgreeWithCookEvent('agreewithcook');
    $(".cookie-message").remove();
  }
});